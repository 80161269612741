import logo from '../assets/img/logo.svg';
import img1 from '../assets/img/HomePage/Banner/bell_01.png';
import img2 from '../assets/img/HomePage/Banner/bell_02.png';
import img3 from '../assets/img/HomePage/Banner/cards.png';
import img4 from '../assets/img/HomePage/Banner/woman.png';

export const images = [
    logo,
    img1,
    img2,
    img3,
    img4,
];

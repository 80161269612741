import React, { useState } from "react";
import classes from './Review.module.scss';
import icon_01 from '../../../assets/img/HomePage/Review/icon_01.png';
import icon_02 from '../../../assets/img/HomePage/Review/icon_02.png';
import icon_03 from '../../../assets/img/HomePage/Review/icon_03.png';
import icon_04 from '../../../assets/img/HomePage/Review/icon_04.png';
import icon_05 from '../../../assets/img/HomePage/Review/icon_05.png';
import stars_01 from '../../../assets/img/HomePage/Review/stars_01.png';
import stars_02 from '../../../assets/img/HomePage/Review/stars_02.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";

const cards = [
    {
        icon: icon_01,
        stars: stars_01,
        title: 'Isabella',
        text: 'As a part-time trader, Trade Signals Lite has been instrumental in helping me make informed trading decisions without spending hours analyzing the markets. The app\'s intuitive design and accurate signals save me time…',
        datetime: '4:20 PM   Apl 1,2024 ',
    },
    {
        icon: icon_02,
        stars: stars_01,
        title: 'Jasper',
        text: 'I\'ve been using Trade Signals Lite for a few weeks now, and I\'m impressed with the consistency of its signals. The app delivers timely notifications..',
        datetime: '3:40 PM   Mar 21,2024',
    },
    {
        icon: icon_03,
        stars: stars_02,
        title: 'Maya',
        text: 'Trade Signals Lite stands out for its versatility, offering signals across various trading instruments and timeframes. Whether I\'m trading crypto or indices, the app provides valuable signals…',
        datetime: '4:20 PM   Mar 21,2024',
    },
    {
        icon: icon_04,
        stars: stars_02,
        title: 'Oliver',
        text: 'One of the standout features of Trade Signals Lite is its comprehensive analysis accompanying each signal. It provides detailed insights into market trends and potential price movements…',
        datetime: '5:30 PM   Mar 21,2024',
    },
    {
        icon: icon_05,
        stars: stars_02,
        title: 'Luna',
        text: 'Trade Signals Lite offers a user-friendly interface with clear and concise signals. I appreciate its simplicity, making it easy to navigate…',
        datetime: '3:20 PM   Mar 21,2024',
    },
];

const Review = () => {
    const [ swiper, setSwiper ] = useState({});
    return (
        <section className="mt container">
            <div className={classes.reviews}>
                <h2 className={`${classes.title} font-48`}>
                    Our 5 star reviews speak for themselves
                </h2>
                <div className={classes.swiperWrap}>
                    <Swiper
                        modules={[ Navigation ]}
                        className="reviewsSwiper no-select"
                        spaceBetween={20}
                        slidesPerView={1}
                        grabCursor
                        onInit={(e) => { setSwiper(e) }}
                        breakpoints={{
                            900: {
                                slidesPerView: 3,
                            },
                            600: {
                                slidesPerView: 2,
                            },
                        }}
                    >
                        {cards.map((card, index) =>
                            <SwiperSlide key={index} className="reviewsSwiperSlide">
                                <div className={classes.card}>
                                    <div className={classes.header}>
                                        <img className={classes.icon} src={card.icon} alt=''/>
                                        <p className={`${classes.name} font-16`}>
                                            {card.title}
                                        </p>
                                        <img className={classes.stars} src={card.stars} alt=''/>
                                    </div>
                                    <p className={`${classes.text} font-16`}>
                                        {card.text}
                                    </p>
                                    <p className={classes.datetime}>
                                        {card.datetime}
                                    </p>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                    <div className={classes.navigation}>
                        <div 
                            onClick={() => swiper.slidePrev()} 
                            className={`${classes.nav} ${classes.navL}`}
                        ></div>
                        <div 
                            onClick={() => swiper.slideNext()} 
                            className={`${classes.nav} ${classes.navR}`}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Review;

import React from "react";
import classes from "./Footer.module.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="mt container">
      <div className={classes.footerWrap}>
        <div className={classes.cols}>
          <div className={classes.col}>
            <p className={classes.descr}>
              Trade Signals Lite helps traders of all levels learn how to trade the financial markets.
            </p>
            <div className={classes.docs}>
              <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
                Terms & Conditions
              </a>
              <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
                Privacy Policy
              </a>
            </div>
          </div>
          <div className={classes.col}>
            <div className={classes.disclaimer}>
              <p>
                The information on the Trade Signals Lite is intended for educational purposes and is not to be construed as investment advice. Trading the financial markets carries a high level of risk and may not be suitable for all investors. Before trading, you should carefully consider your investment objectives, experience, and risk appetite. Only trade with money you are prepared to lose. Like any investment, there is a possibility that you could sustain losses of some or all of your investment whilst trading. You should seek independent advice before trading if you have any doubts. Past performance in the markets is not a reliable indicator of future performance.
              </p>
              <p>
                Trade Signals Lite takes no responsibility for loss incurred as a result of the content provided inside our Trading Room. By signing up as a member you acknowledge that we are not providing financial advice and that you are making the decision on the trades you place in the markets. We have no knowledge of the level of money you are trading with or the level of risk you are taking with each trade.
              </p>
              <p>
                The Trade Signals Lite website uses cookies in order to provide you with the best experience. By visiting our website with your browser set to allow cookies, or by accepting our Cookie Policy notification you consent to our Privacy Policy, which details our Cookie Policy.
              </p>
            </div>
            <div className={classes.address}>
              Address: <b>128 City Road, London, United Kingdom, EC1V 2NX</b>
            </div>
          </div>
        </div>
        <div className={classes.rights}>
          Copyright © 2024 Trade Signals Lite
        </div>
      </div>
    </footer>
  );
};

export default Footer;

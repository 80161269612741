import React from "react";
import Review from "../../components/HomePage/Review/Review";
import WhoCreates from "../../components/HomePage/WhoCreates/WhoCreates";
import WhyUse from "../../components/HomePage/WhyUse/WhyUse";
import TakeYour from "../../components/HomePage/TakeYour/TakeYour";
import Everything from "../../components/HomePage/Everything/Everything";
import WhatAre from "../../components/HomePage/WhatAre/WhatAre";
import Banner from "../../components/HomePage/Banner/Banner";

const HomePage = () => {
  return (
    <div className="">
      <Banner />
      <div className="ovf-hidden">
        <WhatAre />
        <Everything />
        <TakeYour />
        <WhyUse />
        <WhoCreates /> 
        <Review />
      </div>
    </div>
  );
};

export default HomePage;

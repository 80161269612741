import React from "react";
import classes from "./Button.module.scss";

const Button = ({ children }) => {
  return (
    <a 
      className={`${classes.button}`}
      href="/app/trade_signals_lite.apk"
      download
    >
      <span>{children}</span>
    </a>
  );
};

export default Button;

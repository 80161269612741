import React, { useEffect, useRef } from "react";
import classes from './TakeYour.module.scss';
import Button from '../../GlobalComponents/Button/Button';
import image from '../../../assets/img/HomePage/take_your.png';
import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const TakeYour = () => {
    const imageRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(imageRef.current, {
                x: -120,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: imageRef.current,
                    start: "top bottom",
                    end: "bottom bottom",
                    scrub: 4,
                    once: true,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.takeYour}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-48`}>
                        Take your trading to the next level
                    </h2>
                    <p className={`${classes.text} font-16`}>
                        Successful Forex traders weren't born successful; they were taught.
                        <br/>Access world-class daily analytics and access live chat all through a single app.
                    </p>
                    <h4 className={`${classes.subtitle} font-20`}>
                        Sounds good? Download the app today!
                    </h4>
                    <div className={classes.buttons}>
                        <Button>
                            Download 
                        </Button>
                    </div>
                </div>
                <img ref={imageRef} className={classes.image} src={image} alt='' />
            </div>
        </section>
    );
};

export default TakeYour;

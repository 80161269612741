import React, { useEffect, useRef } from "react";
import classes from './Everything.module.scss';
import image from '../../../assets/img/HomePage/everything.png';
import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const Everything = () => {
    const imageRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(imageRef.current, {
                x: 120,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: imageRef.current,
                    start: "top bottom",
                    end: "+=300px",
                    scrub: 4,
                    once: true,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.everything}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-48`}>
                        Everything at your fingertips
                    </h2>
                    <h4 className={`${classes.subtitle} font-16`}>
                        Knowledge is power and information is king when it comes to trading. Why?
                    </h4>
                    <p className={`${classes.text} font-16`}>
                        News moves fundamentals and fundamentals move crypto! Don't get left behind! Move with them by following real-time news, economic news and the latest trading updates affecting the market - all with our app.
                    </p>
                </div>
                <img ref={imageRef} className={classes.image} src={image} alt='' />
            </div>
        </section>
    );
};

export default Everything;

import React, { useEffect, useRef } from "react";
import classes from './WhatAre.module.scss';
import icon_01 from '../../../assets/img/HomePage/WhatAre/icon_01.svg';
import icon_02 from '../../../assets/img/HomePage/WhatAre/icon_02.svg';
import icon_03 from '../../../assets/img/HomePage/WhatAre/icon_03.svg';
import image_01 from '../../../assets/img/HomePage/WhatAre/image_01.png';
import image_02 from '../../../assets/img/HomePage/WhatAre/image_02.png';
import image_03 from '../../../assets/img/HomePage/WhatAre/image_03.png';
import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});


const WhatAre = () => {
    const bodyRef = useRef(null);
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            icon: icon_01,
            title: 'Signals at a glance',
            text: 'Use the in-platform pane to view crypto trading signals including provider, direction and timeframe.',
            image: image_01,
        },
        {
            ref: cardRef_02,
            icon: icon_02,
            title: 'Technical signals',
            text: 'Get chart analysis of your crypto market, along with entry and exit price targets to inform your trade.',
            image: image_02,
        },
        {
            ref: cardRef_03,
            icon: icon_03,
            title: 'Fundamental signals',
            text: 'Together with concise market analysis, you’ll also see entry and exit points, support and resistance levels and the provider’s level of confidence in the signal.',
            image: image_03,
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                x: -120,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top bottom",
                    end: "bottom bottom",
                    scrub: 4,
                    once: true,
                }
            });
            gsap.timeline().fromTo(cardRef_02.current, {
                y: 60,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top bottom",
                    end: "bottom bottom",
                    scrub: 4,
                    once: true,
                }
            });
            gsap.timeline().fromTo(cardRef_03.current, {
                x: 120,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top bottom",
                    end: "bottom bottom",
                    scrub: 4,
                    once: true,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.whatAre}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-48`}>
                        What are trading signals?
                    </h2>
                    <p className={`${classes.text} font-16`}>
                        Trading signals, which are based on developing chart patterns and key levels being reached, are practical "buy" and "sell" recommendations for traders. Our platform offers signals for a crypto market, which you can use to guide your trading decisions.
                    </p>
                </div>
                <div ref={bodyRef} className={classes.cards}>
                    {cards.map((card, index) =>
                        <div ref={card.ref} key={index} className={classes.card}>
                            <div className={classes.cardContent}>
                                <img className={classes.cardIcon} src={card.icon} alt=''/>
                                <h4 className={`${classes.cardTitle} font-20-whatare`}>
                                    {card.title}
                                </h4>
                                <p className={`${classes.cardText} font-16`}>
                                    {card.text}
                                </p>
                            </div>
                            <img className={classes.cardImage} src={card.image} alt=''/>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WhatAre;

import React, { useEffect, useRef } from "react";
import classes from './WhoCreates.module.scss';
import image_01 from '../../../assets/img/HomePage/WhoCreates/image_01.png';
import image_02 from '../../../assets/img/HomePage/WhoCreates/image_02.png';
import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const WhoCreates = () => {
    const bodyRef = useRef(null);
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                x: -100,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top bottom",
                    end: "bottom+=100px bottom",
                    scrub: 3,
                    once: true,
                }
            });
            gsap.timeline().fromTo(cardRef_02.current, {
                x: 100,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top bottom",
                    end: "bottom+=100px bottom",
                    scrub: 3,
                    once: true,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.whoCreates}>
                <h2 className={`${classes.title} font-48`}>
                    Who creates our trading signals?
                </h2>
                <div ref={bodyRef} className={`${classes.cards} font-16`}>
                    <div ref={cardRef_01} className={classes.card}>
                        <p className={classes.text}>
                            Our signals are crafted by advanced algorithms and AI systems designed to analyze vast amounts of data swiftly and accurately.
                        </p>
                        <img className={classes.image} src={image_01} alt='' />
                    </div>
                    <div ref={cardRef_02} className={classes.card}>
                        <p className={classes.text}>
                            Our robotic analysts work tirelessly around the clock, ensuring that you receive timely and reliable trading signals tailored to your needs.
                        </p>
                        <img className={classes.image} src={image_02} alt='' />
                    </div>
                </div>
                <p className={`${classes.descr} font-16`}>
                    Join us today and experience the efficiency and precision of robotically generated trading signals.
                </p>
            </div>
        </section>
    );
};

export default WhoCreates;

import React, { useEffect, useRef } from "react";
import classes from './WhyUse.module.scss';
import image from '../../../assets/img/HomePage/WhyUse/image.png';
import man from '../../../assets/img/HomePage/WhyUse/man.png';
import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const WhyUse = () => {
    const cardRef_01 = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                x: 120,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: cardRef_01.current,
                    start: "top bottom",
                    end: "+=300px",
                    scrub: 4,
                    once: true,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.whyUse}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-48`}>
                        Why use our free trading signals?
                    </h2>
                    <ul className={`${classes.list} font-20`}>
                        <li>
                            Get free real-time trading ideas for crypto.
                        </li>
                        <li>
                            Free up your time, with continuous analysis done for you by AI and robots.
                        </li>
                        <li>
                            See and action trade signals easily on your mobile or desktop.
                        </li>
                    </ul>
                </div>
                <div className={classes.images}>
                    <img className={classes.man} src={man} alt=''/>
                    <img ref={cardRef_01} className={classes.image} src={image} alt=''/>
                    <div className={classes.blur}></div>
                </div>
            </div>
        </section>
    );
};

export default WhyUse;

import _ from 'lodash';
import React, { useEffect, useState, useRef } from "react";
import classes from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import Dropdown from "react-dropdown";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import "react-dropdown/style.css";
import i18n from "i18next";
import $ from "jquery";
import logo from "../../../assets/img/logo.svg";

const languages = [
  { value: "en", label: "EN" },
];

const Header = () => {
  const { t } = useTranslation();
  const [burgerOpened, setBurgerOpened] = useState(false);
  const { pathname } = useLocation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const headerRef = useRef(null);

  const setLocale = (newLang) => {
    i18n.changeLanguage(newLang);
    navigate(_.replace(pathname, `/${lang}/`, `/${newLang}/`));
  };

  useEffect(() => {
    const langs = _.dropRight(i18n.options.supportedLngs);
    if (lang === undefined || !langs.includes(lang)) {
      setLocale('en');
    }
  }, [pathname]);

  const listenScroll = () => {
    if (window.pageYOffset > 70) {
      $(headerRef.current).removeClass(classes.hideBg);
      $(headerRef.current).removeClass(classes.lightBg);
    } else {
      $(headerRef.current).addClass(classes.hideBg);
      $(headerRef.current).removeClass(classes.lightBg);
    }
  }

  useEffect(() => {
    listenScroll();
  }, [burgerOpened]);
  useEffect(() => {
    $(headerRef.current).removeClass(classes.lightBg);
    window.addEventListener("scroll", (event) => {
      listenScroll();
    });
    listenScroll();
  }, [pathname]);
  useEffect(() => {
    setBurgerOpened(false);
  }, [pathname]);
  useEffect(() => {
    if (burgerOpened) {
      $("body").addClass("lock");
    } else {
      $("body").removeClass("lock");
    }
  }, [burgerOpened]);
  useEffect(() => {
    if ($("body").hasClass("lock")) {
      $("body").removeClass("lock");
    }
  }, []);
  window.addEventListener("resize", (event) => {
    setBurgerOpened(false);
  });
  
  return (
    <div
      ref={headerRef}
      className={`${classes.header} ${burgerOpened && classes.headerActive} ${classes.hideBg} no-select`}
    >
      <div className={["container", classes.container].join(" ")}>
        <div className={classes.headerRow}>
          <div className={classes.headerRowLogo}>
            <img src={logo} alt="" />
          </div>
          <div className={classes.headerRowContent}>
            <div className={classes.langs}>
              <Dropdown
                options={languages}
                onChange={(e) => setLocale(e.value)}
                value={lang}
                placeholder="EN"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

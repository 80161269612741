import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import Button from "../../GlobalComponents/Button/Button";
import woman from '../../../assets/img/HomePage/Banner/woman.png';
import cards from '../../../assets/img/HomePage/Banner/cards.png';
import bell_01 from '../../../assets/img/HomePage/Banner/bell_01.png';
import bell_02 from '../../../assets/img/HomePage/Banner/bell_02.png';
import { gsap } from "gsap";
gsap.config({
  force3D: true,
});

const Banner = () => {
    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    const textRef = useRef(null);
    const lightRef = useRef(null);
    const womanRef = useRef(null);
    const cardsRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.fromTo(titleRef.current, {
                x: -300,
                opacity: 0,
            }, {
                x: 0,
                opacity: 1,
                duration: 0.5,
                delay: 1,
            });
            gsap.fromTo(subtitleRef.current, {
                x: 100,
                opacity: 0,
            }, {
                x: 0,
                opacity: 1,
                duration: 0.5,
                delay: 1.5,
            });
            gsap.fromTo(textRef.current, {
                x: -200,
                opacity: 0,
            }, {
                x: 0,
                opacity: 1,
                duration: 0.5,
                delay: 2,
            });
            gsap.fromTo(lightRef.current, {
                x: -700,
            }, {
                x: 0,
                duration: 3.5,
                delay: 0,
            });
            gsap.fromTo(womanRef.current, {
                y: 50,
                scale: 0.95,
            }, {
                y: 0,
                scale: 1,
                duration: 1,
                delay: 0,
            });
            gsap.fromTo(cardsRef.current, {
                y: 50,
                scale: 0.5,
                opacity: 0,
            }, {
                y: 0,
                scale: 1,
                opacity: 1,
                duration: 0.5,
                delay: 2.5,
            });
        }
    }, []);
    return (
        <section className={classes.bannerWrap}>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h1 ref={titleRef} className={`${classes.title} font-96`}>
                            Trade Signals Lite
                        </h1>
                        <h2 ref={subtitleRef} className={`${classes.subtitle} font-32`}>
                            Becoming a trader is even easier with us
                        </h2>
                        <div ref={textRef} className={`${classes.text} font-16`}>
                            <p>
                                Spot potential trades with free crypto signals.
                            </p>
                            <p>
                                Techniques, priceless resources, and knowledgeable trading coaches to help you succeed straight out of your wallet.
                            </p>
                        </div>
                        <div className={classes.download}>
                            <p className={`${classes.downloadText} font-16`}>
                                Download our app now to join thousands of others.
                            </p>
                            <div className={classes.downloadBtn}>
                                <Button>
                                    Download
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.images}>
                        <img ref={womanRef} className={classes.woman} src={woman} alt='' />
                        <img ref={cardsRef} className={classes.cards} src={cards} alt='' />
                        <img className={classes.bell_01} src={bell_01} alt='' />
                        <img className={classes.bell_02} src={bell_02} alt='' />
                        <div ref={lightRef} className={classes.light}></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
